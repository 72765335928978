@charset "UTF-8";

// We are pulling in pieces of the nginbar styles until we can switch over entirely
$theme-scope: "html"; // rely on prefixed classes only, since ngin moves dropdowns around in the dom

@import "../ngin-bar/config";
@import "../ngin-bar/variables";
@import "../ngin-bar/mixins";

@import "../ngin-bar/components/ngin-bar-font";
// @import "components/ngin-bar";

@import "../ngin-bar/components/card";
@import "../ngin-bar/components/dropdown";
// @import "components/message";
// @import "components/mobile-nav";
// @import "components/notification";

// Hacks to get these to play nice with ngin

%nb-base {
  font-weight: 400;
  line-height: 1;

  // apply some styles to this AND all children
  &, * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: NginBarFont;
  }
}

.NGIN_tip {

  .nb-options {
    @extend %nb-base;
    display: block;
    pointer-events: auto;
    position: relative;
  }

  // Ngin themes overider the <a> tag with no other selectors (yuck)
  .nb-card,
  .nb-link {
    color: inherit;
    text-decoration: none;
  }
}
