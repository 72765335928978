@font-face {
  font-family: 'NginBarFont';
  src: url('#{$shared-font-path}/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
}

// ## Open Sans Semi Bold
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('#{$shared-font-path}/OpenSans-SemiBold.woff') format('woff');
}
