#{$theme-scope} {

  %nb-options {
    background: $bg-default;
    border: 1px solid $bg-default;
    border-radius: 0 0 2px 2px;
    box-shadow: $nb-dropdown-shadow;
    color: $color-gray-85;
    display: none;
    list-style: none;
    width: $nb-options-width;
    pointer-events: none;
    position: absolute;
    left: calc(50% - #{$nb-options-width / 2});
    top: 100%;
    white-space: nowrap;
  }

  %nb-dropdown-link {
    background: transparent;
    border: 0;
    line-height: $ngin-bar-height;
    outline: 0;
    text-align: center;
    text-transform: none;
  }

  %nb-options__caret {
    border-bottom-color: inherit;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-top-color: transparent;
    border-width: 0 $nb-options-caret-size $nb-options-caret-size $nb-options-caret-size;
    bottom: calc(100% + 1px);
    display: block;
    height: $nb-options-caret-size;
    position: absolute;
    left: calc(50% - #{$nb-options-caret-size});
    width: $nb-options-caret-size * 2;
  }

  .nb-dropdown-link {
    @extend %nb-dropdown-link;
    padding: 0 12px;
  }

  .nb-dropdown-link--icon {
    @extend %nb-dropdown-link;
    color: $gray;
    display: inline-block;
    padding: 0;
    width: $nb-link-width-mobile;

    &:hover {
      color: $gray-medium;
    }

    @media (min-width: $tablet-width) {
      width: $nb-link-width;
    }
  }

  .nb-dropdown-link__icon {
    display: inline-block;
    fill: currentColor;
    width: $nb-link-icon-size;
    height: $nb-link-icon-size;
    vertical-align: middle;
    margin-top: -2px;
  }

  .nb-dropdown {
    position: relative;
    padding: 0;
    margin: 0;
    outline: none;

    &.nb-active {
      > .nb-dropdown-link,
      > .nb-dropdown-link--icon {
        background: rgba($gray, .3);
        color: $gray-medium;
      }
    }
  }

  .nb-options {
    @extend %nb-options;
  }

  .nb-options-scroll {
    display: block;
    max-height: calc(95vh - 80px); // sync this with .nb-options-sections
    overflow-y: scroll;
  }

  .nb-options--dark {
    @extend %nb-options;
    border-color: $gray;
    .nb-options__title {
      background: $gray;
      color: $color-content-inverse;
    }
  }

  .nb-options__title {
    background: $gray-xxlight;
    padding: 8px 12px 12px;
  }

  .nb-options__caret {
    @extend %nb-options__caret;
  }

  .nb-options__buttons {
    align-items: stretch;
    background: $bg-default;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    display: flex;
    flex-direction: row;
  }

  .nb-options__button {
    flex: 1 0 auto;
    padding: 15px 12px;
    text-align: center;

    &:hover { background: $gray-xxlight; }
  }

  .nb-spinner {
    fill: $gray-xlight;
    height: 40px;
    margin: 10px 0;
    width: 100%;
  }

  .nb-active > .nb-options {
    display:block;
    pointer-events: auto;
  }

  .nb-option {

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    .nb-link {
      display: block;
      font-size: 14px;
      overflow: hidden;
      padding: 8px 12px;
      text-decoration: none;
      text-overflow: ellipsis;

      &:hover {
        background: $gray-xxlight
      }
    }
  }

  .nb-options-sections {
    display: flex;
    flex-direction: column;
    max-height: calc(95vh - 80px);
    overflow: hidden;
  }

  %nb-options-section {
    display: block;
    flex: 1 1 auto;
    padding: 16px 0;
  }

  .nb-options-section {
    @extend %nb-options-section;

    &__title {
      color: $color-gray-50;
      display: block;
      font-size: 12px;
      margin-bottom: 4px;
      padding: 0 12px;
      text-transform: uppercase;
    }
  }

  .nb-options-section--scroll {
    @extend %nb-options-section;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .nb-options-section__separator {
    background: $color-gray-10;
    display: block;
    flex: 0 0 auto;
    height: 1px;
    margin: 0 12px;
  }

  &.nb-light {

    .nb-dropdown {
      position: relative;
      padding: 0;
      margin: 0;
      outline: none;

      &.nb-active {
        > .nb-dropdown-link,
        > .nb-dropdown-link--icon {
          background: none;
          color: $color-gray-85;
        }
      }
    }

    .nb-dropdown-link--icon {
      color: $gray-light;
    }

    .nb-active .nb-dropdown-link--icon {
      color: $color-highlight;
    }

    .nb-options {
      border-color: #fff;
      border-radius: 2px;
    }

    .nb-options__caret {
      border-width: 0 $nb-options-caret-size $nb-options-caret-size;
      border-bottom-color: $color-gray-10;
      left: calc(50% - #{$nb-options-caret-size});

      &::before {
        content: '';
        border: inherit;
        border-bottom-color: white;
        top: 0;
        left: -$nb-options-caret-size;
        position: absolute;
        transform: translateY(1px);
      }
    }
  }

  @media (max-width: $media-small) {
    .nb-spinner {
      margin-top: 30vh;
    }
    .nb-options,
    .nb-options--dark {
      height: calc(100% - #{$ngin-bar-height});
      left: 0;
      position: fixed;
      top: $ngin-bar-height;
      width: 100%;
    }
    .nb-options__title {
      padding-right: $nb-card-padding-mobile;
      padding-left: $nb-card-padding-mobile;
    }
    .nb-options__scroll,
    .nb-options-sections {
      max-height: 100%;
    }
  }

}
