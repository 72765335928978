#{$theme-scope} {

  .nb-card {
    align-items: center;
    background: $bg-default;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 1.2;
    overflow: hidden;
    padding: 10px 12px;

    &:hover {
      background: $gray-xxlight;
    }

    > * {
      flex: 1 1 auto;

      &:not(:first-child) {
        padding-left: 12px;
      }
    }
  }

  %nb-card__image {
    background: $color-gray-10 center center;
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    overflow: hidden;
  }

  .nb-card__image {
    @extend %nb-card__image;
    height: $nb-card-image-size;
    width: $nb-card-image-size;
    flex: 0 0 $nb-card-image-size;
  }

  .nb-card__image--large {
    @extend %nb-card__image;
    height: $nb-card-image-size-large;
    width: $nb-card-image-size-large;
    flex: 0 0 $nb-card-image-size-large;
  }

  .nb-card__info {
    flex: 100 1 100%;
    width: 50%; // trick ellipsis into working, flex basis actually sets width
  }

  %nb-card__title,
  .nb-card__title {
    @extend %ellipsis;
    font-size: 20px;
  }

  %nb-card__subtitle,
  .nb-card__subtitle {
    @extend %ellipsis;
    color: $gray-medium;
  }

  @media (max-width: $media-small) {
    .nb-card {
      padding-right: $nb-card-padding-mobile;
      padding-left: $nb-card-padding-mobile;
    }
  }
}
